import React, { useState, useEffect, useRef } from 'react';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Box,
    Slider,
    IconButton,
} from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const MusicPlayer = (props) => {
    const { src, currentAudio, setCurrentAudio } = props;

    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [error, setError] = useState(null); // State to track errors

    const handlePlayPause = () => {
        if (!audioRef.current) {
            setError('Audio player is not available.');
            return;
        }

        try {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                if (audioRef.current.readyState >= 3) { // HAVE_FUTURE_DATA or higher
                    audioRef.current.play();
                } else {
                    setError('Audio is not ready to play.');
                }
            }
            setIsPlaying(!isPlaying);
        } catch (err) {
            console.error('Error toggling playback:', err);
            setError('Error: Unable to play or pause the audio.');
        }
    };

    const handleSliderChange = (event, newValue) => {
        if (audioRef.current) {
            audioRef.current.currentTime = newValue;
        }
        setCurrentTime(newValue);
    };

    const handleVolumeChange = (event, newValue) => {
        if (audioRef.current) {
            audioRef.current.volume = newValue;
        }
        setVolume(newValue);
    };

    useEffect(() => {
        const audioElement = audioRef.current;

        const handleTimeUpdate = () => {
            setCurrentTime(audioElement?.currentTime || 0);
        };

        const handleLoadedMetadata = () => {
            setDuration(audioElement?.duration || 0);
        };

        const handleEnded = () => {
            setIsPlaying(false);
        };

        const handleCanPlay = () => {
            setError(null); // Clear errors when ready
        };

        if (audioElement) {
            audioElement.addEventListener('timeupdate', handleTimeUpdate);
            audioElement.addEventListener('loadedmetadata', handleLoadedMetadata);
            audioElement.addEventListener('ended', handleEnded);
            audioElement.addEventListener('canplay', handleCanPlay);
        }

        return () => {
            if (audioElement) {
                audioElement.removeEventListener('timeupdate', handleTimeUpdate);
                audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
                audioElement.removeEventListener('ended', handleEnded);
                audioElement.removeEventListener('canplay', handleCanPlay);
                audioElement.pause();
            }
        };
    }, []);

    useEffect(() => {
        if (audioRef.current && src) {
            audioRef.current.src = src; // Explicitly set the source
            setError(null); // Clear previous errors
            audioRef.current
                .play()
                .then(() => {
                    setIsPlaying(true);
                })
                .catch((err) => {
                    console.error('Error playing audio:', err);
                    setError('Error: Unable to play the audio.');
                    setIsPlaying(false);
                });
        }
    }, [src]);

    return (
        <Box>
            {error && (
                <Typography color="error" sx={{ marginBottom: '10px' }}>
                    {error}
                </Typography>
            )}
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'none' }}>
                    <audio ref={audioRef} />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button onClick={handlePlayPause} disabled={!!error}>
                            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                        </Button>
                        <VolumeDownIcon />
                        <Slider
                            value={volume}
                            onChange={handleVolumeChange}
                            min={0}
                            max={1}
                            step={0.01}
                            sx={{width: '100px', marginLeft: '10px'}}
                        />
                        <VolumeUpIcon />
                        <Typography>
                            {formatTime(currentTime)} / {formatTime(duration)}
                        </Typography>
                        <Slider
                            value={currentTime}
                            onChange={handleSliderChange}
                            max={duration}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MusicPlayer;
