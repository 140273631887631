import React, { useEffect, useState, useRef } from 'react';
import * as DatabaseService from '../../../../services/DatabaseService';
import MuiDrawer from '../../../../components/MuiDrawer';
import Table_Section from './output/Table_Section';
import DialogOutput from '../../../../components/DialogOutput';
import GOÄ_Abrechnungskompass_Calculation from '../../GOÄ_Abrechnungskompass_Calculation';
import FileSaver from 'file-saver';

export default function CONDataPage() {
    const [listJSONData, setJSONData] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogData, setDialogInformation] = useState({ title: "", information: "", status: "" });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);

    const getGoaeFachgruppenvergleichCSVList = async (page, rowsPerPage, search) => {
        try {
            const JSONData = await DatabaseService.getGoaeFachgruppenvergleichCSVList((page + 1), rowsPerPage, search);
            if (JSONData && JSONData.rows) {
                setJSONData(JSONData.rows);
            }
            
            if (JSONData && 'totalCount' in JSONData) {
                const totalCount = parseInt(JSONData.totalCount);
                if (!isNaN(totalCount)) {
                    setTotalRowsCount(totalCount);
                } else {
                    console.warn('Invalid total count value:', JSONData.totalCount);
                    setTotalRowsCount(0);
                }
            } else {
                console.warn('Total count property missing in response');
                setTotalRowsCount(0);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    const downloadCSV = async (id, filename) => {
        try{
    
          const CSVData = await DatabaseService.downloadGoaeFachgruppenvergleichCSV(id);
    
          if(CSVData.ok) {
            const blob = await CSVData.blob();
            FileSaver.saveAs(blob, filename + '.csv');
          } else {
              console.error('Server responded with status: ' + CSVData.status);
          }
    
        }catch(e){}
      }

    useEffect(() => {
        setDialogInformation({ information: "Loading...", status: "loading" });
        setLoading(true);
        getGoaeFachgruppenvergleichCSVList(page, rowsPerPage, search)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        const updatedRows = listJSONData.map(item => ({
            id: item.id,
            salutation: item.salutation,
            user_title: item.user_title,
            fname: item.fname,
            lname: item.lname,
            user_input: item.user_input,
            user_input_privatpatientinnen: item.user_input_privatpatientinnen,
            created_at: item.created_at
        }));
        setRows(updatedRows);
    }, [listJSONData]);

    const handleViewWithGOAEFachgruppenvergleich = (user_input, user_input_privatpatientinnen, created_at, salutation, user_title, fname, lname) => {
        const parsedData = JSON.parse(user_input);
        setDialogInformation({ title: "", information: {user_input: parsedData, user_input_privatpatientinnen, created_at, pageUserInfo: {salutation, user_title, fname, lname}}, status: "view" });
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleDownloadCSV = async (id, filename) => {
        downloadCSV(id, filename);
    }

    return (
        <MuiDrawer>
            <br />
            <Table_Section 
                tableTitle="GOÄ Fachgruppenvergleich Abrechnungskompass Benutzereingabe-Protokolle"
                loading={loading}
                rows={rows}
                order={order}
                orderBy={orderBy}
                selected={selected}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                dense={dense}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSelected={setSelected}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setDense={setDense}
                setSearch={setSearch}
                search={search}
                handleDownloadCSV={handleDownloadCSV}
                handleViewWithGOAEFachgruppenvergleich={handleViewWithGOAEFachgruppenvergleich}
            />

            {
                dialogData.status === "loading" || dialogData.status === "view" ? (
                <DialogOutput
                    fullScreen={(dialogData.status === "view") ? true: false}
                    open={open}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    backgroundColor={dialogData.status === "view" ? '#F1F2F4' : undefined}
                    output={dialogData.status === "view" ? (
                        <GOÄ_Abrechnungskompass_Calculation 
                            user_input={dialogData.information.user_input} 
                            user_input_privatpatientinnen={dialogData.information.user_input_privatpatientinnen} 
                            created_at={dialogData.information.created_at} 
                            pageUserInfo={dialogData.information.pageUserInfo}
                            view_only_mode={true}
                        />
                      ) : null}
                />
                ) : null
            }

        </MuiDrawer>
    );
}