export const getGoaeFachgruppenvergleichCSVList = async (page, rowsPerPage, search) => {

    try{

        const response = await fetch(`/api/goae_fachgruppenvergleich_csv_list?page=${page}&perPage=${rowsPerPage}&s=${search}`);

        const jsonData = await response.json();

        return jsonData;

    } catch (error){
        console.error(error.message);
    }
}