import { styled } from '@mui/system';

const InputTableStyle = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    gap: '20px',
    margin: '20px 0',
    '& .table-head-row': {
      backgroundColor: '#006400',
    },
    '& .table-head-cell': {
      border: '1px solid #949494',
      color: '#ffffff',
      textAlign: 'center',
    },
    '& .content-div': {
      backgroundColor: '#fff',
      marginBottom: '20px',
      width: '100%',
    },
    '& .table-container': {
      borderRadius: 0,
    },
    '& .table': {
      border: '1px solid #949494',
    },
    '& .table-body-row': {
      backgroundColor: '#CAE3B6',
    },
    '& .table-body-cell': {
      color: '#134020',
      border: '1px solid #949494',
      textAlign: 'center',
    },
    '& .table-body-cell-header-description': {
        color: '#134020',
        border: '1px solid #949494',
    },
    '& .last-child-cell': {
      border: '1px solid #949494',
    },
    '& .gop-link': {
      cursor: 'pointer',
    },
    '& .text-field': {
      marginTop: 0,
      '& .MuiInputBase-input': {
        textAlign: 'center',
        borderRadius: 0,
      },
      '& .MuiInputBase-root': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'black',
        color: 'black',
      },
    },
  }),
);

export default InputTableStyle;