import MuiDrawer from '../../components/MuiDrawer';
import GOÄ_Abrechnungskompass_Calculation from './GOÄ_Abrechnungskompass_Calculation';

export default function GOÄ_Abrechnungskompass_Calculation_Main() {

  return (
            <MuiDrawer>
                <GOÄ_Abrechnungskompass_Calculation />
            </MuiDrawer>
          );
}