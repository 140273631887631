import React, { useEffect, useState } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Link } from '@mui/material';
import { Tooltip } from '@mui/material';
import { TextField } from '@mui/material';

import { ContentComponent, MembershipStatus, MembershipStatusDanke } from '../../components/ContentForFullMemberShip';
import ViewFormOutputMembership from '../../components/ViewFormOutputMembership';
import DialogOutput from '../../../../components/DialogOutput';
import ViewFormOutput from '../../components/ViewFormOutput';

import ViewFormOutputStyle from '../../styles/ViewFormOutputStyle'

import * as DatabaseService from  '../../../../services/DatabaseService';

import checkDeviationPercentStatus from '../../calculations/checkDeviationPercentStatus';

function insertGoaeFachgruppenvergleichCSVData_func(data, input, patienten) {
    return DatabaseService.insertGoaeFachgruppenvergleichCSVData(data, input, patienten)
}

export default function TableSection(props) {

    const { rowsResult, patienten, goaeZifferNumbersInput, viewOnlyMode, userStatus, debug, maxHeight=500 } = props;

    let processOutputCSV = "";

    useEffect(() => {

        /*if (!viewOnlyMode && rowsResult && rowsResult.length > 0) {
          insertAbrechnungskompassCSVData_func(processOutputCSV, gopNumbersInput, scheine, kv)
        }*/

        if (!viewOnlyMode && rowsResult && rowsResult.length > 0) {
            insertGoaeFachgruppenvergleichCSVData_func(processOutputCSV, goaeZifferNumbersInput, patienten)
        }
  
          //console.log("processOutputCSV")
          //console.log(processOutputCSV)

      }, [rowsResult, patienten, goaeZifferNumbersInput])

    /*
    useEffect(() => {

      if (!viewOnlyMode && rowsResult && rowsResult.length > 0) {
        insertAbrechnungskompassCSVData_func(processOutputCSV, gopNumbersInput, scheine, kv)
      }

    }, [rowsResult, scheine, gopNumbersInput, kv])*/

    /*useEffect(() => {
    }, [rowsResult]);*/

    const TableFilter = (props) => {

        const { rowsResult, titleHeader="" } = props;

        let currentHeader = null;
        let backgroundColorRow = '#00000000';
        let countRowForBgColor = 0; //this will help start with a white row

        processOutputCSV += "GOÄ-Ziffer,Leistung,Anzahl,Soll,Abweichung,Abweichung %,Gesamtpotenzial in €,Ihr Potenzial in €" + "\n"

        /*Dialog Box*/
        const [open, setOpen] = React.useState(false);
        const [dialogData, setDialogInformation] = React.useState({ title: "", information: "", status: "" });
        const [openDialogFeatures, setOpenDialogFeatures] = React.useState(false);
        /*End of Dialog Box*/ 

        const rows = rowsResult.sort((a, b) => {
            const aIsNumeric = /^\d+$/.test(a.ziffer);
            const bIsNumeric = /^\d+$/.test(b.ziffer);
    
            if (aIsNumeric && bIsNumeric) {
              return parseInt(a.ziffer) - parseInt(b.ziffer);
            } else if (aIsNumeric) {
              return -1;
            } else if (bIsNumeric) {
              return 1;
            } else {
              return a.ziffer.localeCompare(b.ziffer);
            }
        });

        /*console.log("rows")
        console.log(rows)*/

         /* Dialog Box */
        const handleViewForm = (ziffer, description) => {
            setDialogInformation({ title: "", information: description, status: "view" });
            setOpen(true);
        }
  
        const handleViewFormDialogFeatures = () => {
            setDialogInformation({ title: "", information: "", status: "view" });
            setOpenDialogFeatures(true);
        }
  
        const handleClose = () => {
            setOpen(false);
            setOpenDialogFeatures(false);
        };
        /* Dialog Box */

        return (<>

            <TableContainer component={Paper} sx={{borderRadius:0, maxHeight: maxHeight}}>
                <Table size="small" aria-label="simple table" sx={{tableLayout: 'auto'}}>
                    <TableHead>
                        {
                            (userStatus !== 3) ?
                        
                            <TableRow sx={{
                                            backgroundColor: '#006400', 
                                            '& .MuiTableCell-root': { color: 'white', textAlign: 'right', whiteSpace: 'nowrap'},
                                            position: 'sticky',
                                            top: 0, // Ensures the header sticks to the top
                                            zIndex: 1, // Keeps it above other content when scrolling
                                        }}>
                                <TableCell  sx={{textAlign: 'left!important', width: '190px'}}>GOÄ-Ziffer</TableCell>
                                <TableCell  sx={{textAlign: 'left!important'}}>Leistung</TableCell>
                                <TableCell  sx={{textAlign: 'right!important'}}>Anzahl</TableCell>
                                <TableCell>Soll</TableCell> {/*Ideal Number of Treatments*/}
                                <TableCell>Abweichung</TableCell> {/*Deviation*/}
                                <TableCell>Abweichung in %</TableCell>{/*Deviation in %*/}
                                {/*<TableCell>Abweichung zur Fachgruppe</TableCell>*/} {/*Abweichung zur Fachgruppe*/}
                                <TableCell>Gesamtpotenzial in €</TableCell>
                                <TableCell>Ihr Potenzial in €</TableCell>
                            </TableRow>
                         : ""
                        }
                    </TableHead>
                    <TableBody sx={{color: '#134020',  position: 'relative', '& .MuiTableCell-root': { border:0 }}}>
                        <MembershipStatus 
                            userStatus={userStatus}
                            onClick={()=>{ handleViewFormDialogFeatures() }}
                        />
                        {
                            rows.map((row, index) => {
                                const tableRows = [];
    
                                let outputZiffer = row.ziffer;
                                let outputTitle = row.title;
                                let outputAnzahl = row.anzahl;
                                let outputIdealNumberOfTreatments = parseFloat(row.ideal_number_of_treatments).toFixed(0);
                                let outputDeviation = ((!isNaN(parseFloat(row.deviation))) ? parseFloat(row.deviation).toFixed(0).toString().replace('.', ',') : 0);
                                let outputDeviationInPercent = isNaN(parseFloat(row.deviation_in_percent)) ? '0' : parseFloat(row.deviation_in_percent).toFixed(2).toString().replace('.', ',') + '%';
                                let outputIhrPotenzialInEuro =  (typeof row.ihr_potenzial_show === 'undefined') ? 
                                                                            parseFloat(row.ihr_potenzial).toFixed(2).toString().replace('.', ',') : 
                                                                            (row.ihr_potenzial_show) ? 
                                                                                (typeof row.ihr_potenzial === 'undefined') ? 0 : parseFloat(row.ihr_potenzial).toFixed(2).toString().replace('.', ',')
                                                                            : '-';
                                let outputGezamtpotenzial = (typeof row.gesamtpotenzial === 'undefined') ? 0 : parseFloat(row.gesamtpotenzial).toFixed(2).toString().replace('.', ',');
                                
                                if((checkDeviationPercentStatus(row.deviation_in_percent_status).backgroundColor !== '#00000000')){
                                    backgroundColorRow = checkDeviationPercentStatus(row.deviation_in_percent_status).backgroundColor;
                                }else{
                                    backgroundColorRow = (countRowForBgColor % 2 === 0 ? 'white' : '#F1F2F4')
                                }
    
    
                                tableRows.push(
                                    <TableRow
                                        key={row.id}
                                        sx={{ 
                                            '&:last-child td, &:last-child th': { border: 0 }, 
                                            backgroundColor: backgroundColorRow,
                                            '& .MuiTableCell-root': {color: checkDeviationPercentStatus(row.deviation_in_percent_status).color, textAlign: 'right'},
                                            '& .MuiTableCell-root a': {color: checkDeviationPercentStatus(row.deviation_in_percent_status).color_href, textDecorationColor: checkDeviationPercentStatus(row.deviation_in_percent_status).color_href }
                                        }} 
                                    >
                                        <TableCell sx={{textAlign: 'left!important'}}>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={
                                            (row.description !== null) 
                                                ? <>
                                                    <Link 
                                                        sx={{cursor:'pointer'}} 
                                                        onClick={()=>{ handleViewForm(outputZiffer, row.description) }}
                                                    >{outputZiffer}
                                                    </Link>
                                                </> : <>{outputZiffer}</>
                                            }
                                        />
                                        </TableCell>
                                        <TableCell sx={{textAlign: 'left!important'}}>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={
                                            <Tooltip title={outputTitle} placement="top-start">
                                                <TextField 
                                                    defaultValue={outputTitle} 
                                                    size="small" 
                                                    fullWidth  
                                                    InputProps={{
                                                    readOnly: true,
                                                    sx: {
                                                            fontSize: '14px',
                                                            '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes the border color
                                                            },
                                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes border color on hover
                                                            },
                                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent', // Removes border color on focus
                                                            },
                                                        }
                                                    }}
                                                    inputProps={{
                                                    style: {
                                                        padding: '3px',   // Inner padding of the input element
                                                        overflow: 'hidden',         // Hide overflowing content
                                                        whiteSpace: 'nowrap',       // Prevent text from wrapping
                                                        textOverflow: 'ellipsis',   // Show ellipsis (...) when text overflows
                                                        color: (checkDeviationPercentStatus(row.deviation_in_percent_status).color)
                                                    },
                                                    }}
                                                />
                                            </Tooltip>
                                            }
                                        />
                                        </TableCell>
                                        <TableCell sx={{textAlign: 'right!important'}}>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={outputAnzahl} 
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={outputIdealNumberOfTreatments} 
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={outputDeviation} 
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={outputDeviationInPercent} 
                                        />
                                        </TableCell>
                                        {/*<TableCell>
                                        {
                                            (typeof row.abweichung_zur_fachgruppe_update === 'undefined') ? 
                                                (Number.isNaN(parseFloat(row.abweichung_zur_fachgruppe))) ? 0 : parseFloat(row.abweichung_zur_fachgruppe).toFixed(2).toString().replace('.', ',') : 
                                                (Number.isNaN(parseFloat(row.abweichung_zur_fachgruppe_update))) ? 0 : parseFloat(row.abweichung_zur_fachgruppe_update).toFixed(2).toString().replace('.', ',')
                                        } %
                                        </TableCell>*/} {/*Abweichung zur Fachgruppe*/}
                                        <TableCell>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ outputGezamtpotenzial }
                                        />
                                        </TableCell>
                                        <TableCell>
                                        <ContentComponent 
                                            userStatus={userStatus} 
                                            content={ outputIhrPotenzialInEuro }
                                        />
                                        </TableCell>
                                    </TableRow>
                                );
    
                                /*processOutputCSV += "GOÄ-Ziffer,
                                                       Leistung,
                                                       Anzahl,
                                                       Soll,
                                                       Abweichung,
                                                       Abweichung %,
                                                       Gesamtpotenzial in €,
                                                       Ihr Potenzial in €" + "\n"*/
                                
                                processOutputCSV +=  '"' + outputZiffer + '",' +
                                                       '"' + outputTitle + '",' +
                                                       '"' + outputAnzahl  + '",' +
                                                       '"' + outputIdealNumberOfTreatments + '",' +
                                                       '"' + outputDeviation + '",' +
                                                       '"' + outputDeviationInPercent + '",' +
                                                       '"' + outputGezamtpotenzial + '",' +
                                                       '"' + outputIhrPotenzialInEuro + '"' + '\n'; 
                                                       

                                countRowForBgColor++;
    
                                return (userStatus !== 3) ? tableRows : "";
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>

                <DialogOutput
                    fullScreen={false} // Set the value according to your requirement
                    open={open}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    output={<ViewFormOutputStyle><ViewFormOutput description={dialogData.information} /></ViewFormOutputStyle>}
                    maxWidth='md'
                />

                <DialogOutput
                    fullScreen={false} // Set the value according to your requirement
                    open={openDialogFeatures}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    maxWidth='lg'
                    output={<ViewFormOutputMembership />}
                />

        </>);
    }

    return(<>
        <MembershipStatusDanke userStatus={userStatus} />

        <TableFilter rowsResult={rowsResult} />
    </>);
}