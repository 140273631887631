import React from 'react';
import { Grid } from '@mui/material';

import PieChartFilter from '../../chart_filters/PieChartFilter';
import SpeedometerFilter from '../../chart_filters/SpeedometerFilter';
import StatusChartFilter from '../../chart_filters/StatusChartFilter';

const Charts_Section = (props) => {
    const { rowsResult, total_ihr_potenzial_included, gesamtpotenzial_included } = props;

    return (<>

        <Grid container spacing={2}>
            <Grid item xs={4}>
                <PieChartFilter total_ihr_potenzial_included={total_ihr_potenzial_included} gesamtpotenzial_included={gesamtpotenzial_included} />
            </Grid>
            <Grid item xs={4}>
                <SpeedometerFilter total_ihr_potenzial_included={total_ihr_potenzial_included} gesamtpotenzial_included={gesamtpotenzial_included} />               
            </Grid>
            <Grid item xs={4}>
                <StatusChartFilter rowsResult={rowsResult} />
            </Grid>
        </Grid>

    </>);
}

export default Charts_Section;