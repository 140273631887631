import React, { useEffect, useState, useRef } from 'react';
import * as DatabaseService from '../../../../services/DatabaseService';
import MuiDrawer from '../../../../components/MuiDrawer';
import Table_Section from './output/Table_Section';
import FileUploadForm from './components/FileUploadForm';
import DialogOutput from '../../../../components/DialogOutput';
import ViewCONData from './output/View_CON_Data';
import EBM_Abrechnungskompass from '../../../EBM_Abrechnungskompass/EBM_Abrechnungskompass';

export default function CONDataPage() {
    const [listJSONData, setJSONData] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalRowsCount, setTotalRowsCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [open, setOpen] = useState(false);
    const [dialogData, setDialogInformation] = useState({ title: "", information: "", status: "" });
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(false);
    const fileInput = useRef(null);

    

    const getCONData = async (page, rowsPerPage, search) => {
        try {
            const JSONData = await DatabaseService.getCONData((page + 1), rowsPerPage, search);
            if (JSONData && JSONData.rows) {
                setJSONData(JSONData.rows);
            }
            
            if (JSONData && 'totalCount' in JSONData) {
                const totalCount = parseInt(JSONData.totalCount);
                if (!isNaN(totalCount)) {
                    setTotalRowsCount(totalCount);
                } else {
                    console.warn('Invalid total count value:', JSONData.totalCount);
                    setTotalRowsCount(0);
                }
            } else {
                console.warn('Total count property missing in response');
                setTotalRowsCount(0);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }

    useEffect(() => {
        setDialogInformation({ information: "Loading...", status: "loading" });
        setLoading(true);
        getCONData(page, rowsPerPage, search)
            .then(() => setLoading(false))
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [page, rowsPerPage, search]);

    useEffect(() => {
        const updatedRows = listJSONData.map(item => ({
            id: item.id,
            salutation: item.salutation,
            user_title: item.user_title,
            fname: item.fname,
            lname: item.lname,
            con_data_json: item.con_data_json,
            scheine_count: item.scheine_count,
            created_at: item.created_at,
            kv: item.kv,
            kv_name: item.kv_name
        }));
        setRows(updatedRows);
    }, [listJSONData]);

    const handleCONDataView = (json_data) => {

        //console.log("json_data: ");
        //console.log(json_data);
        const parsedData = JSON.parse(json_data);
        setDialogInformation({ title: "", information: parsedData, status: "view" });
        setOpen(true);
    }

    const handleCONDataViewWithEBM = (json_data, scheine_count, kv, created_at, salutation, user_title, fname, lname, kv_name) => {
        const parsedData = JSON.parse(json_data);
        setDialogInformation({ title: "", information: {json_data: parsedData, scheine_count, kv, created_at, pageUserInfo: {salutation, user_title, fname, lname, kv_name}}, status: "view", with_ebm: true });
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onDelete = (event) => {
        setDialogInformation({ information: "Löschend...", status: "loading" });
        setOpen(true);
        DatabaseService.delCONData(selected)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to delete file');
                }
            })
            .then(result => {
                setOpen(false);
                getCONData(page, rowsPerPage, search);
                setSelected([]);
            })
            .catch(error => {
                console.error('Error deleting file:', error);
                setOpen(false);
            });
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const fileInput = event.target.elements.fileInput;
        if (fileInput.files.length > 0) {
            handleFileUpload({ target: fileInput });
        }
    };

    const handleFileUpload = (e) => {
        try {
            const file = e.target.files[0];
    
            if (file && file.name.toLowerCase().endsWith('.con')) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = event.target.result;
                handleProcessConData(content);
            };
            reader.readAsText(file);
            } else {
                alert('Please select a .con file');
            }
        } finally {
            //console.log('done process');
        }
    };
    
    const handleProcessConData = async (content) => {
        //const lines = content.split('\n');
        const lines = content.split('\n').map(line => line.replace('\r', ''));
        const conData = [];
        const occurrences = {};
        let scheineCount = 0;

        let _3101Count = 0;
        //let _3101Output = "";

        let _3102Count = 0;
        //let _3102Output = "";

        /*let _0203101Count = 0;
        let _0203101Output = "";*/

        lines.forEach(line => {
            let _5001Idx = line.indexOf('5001');
            if (_5001Idx !== -1) {
              let digitsAfter5001 = line.substring(_5001Idx + 4);
              conData.push(digitsAfter5001);
        
              // Count occurrences of digits after '5001'
              if (occurrences[digitsAfter5001]) {
                occurrences[digitsAfter5001]++;
              } else {
                occurrences[digitsAfter5001] = 1;
              }
            }
        
            // Check for '0733013' in the line and count occurrences
            /*if (line.includes('0733013')) {
                scheineCount++;
            }*/

            if (/(?<=\d{3})3101(?!\d)/.test(line)) {
                //_3101Output += line + "\n";
                _3101Count++;
            }

            if (/(?<=\d{3})3102(?!\d)/.test(line)) {
                //_3102Output += line + "\n";
                _3102Count++;
            }

            /*if(line.includes('0203101')) {
                _0203101Output += line + "\n";
                _0203101Count++;
            }*/

          });

        const output = Object.keys(occurrences).map(key => ({ id: key, occurrences: occurrences[key] }));
        //console.log('Output:', output);

        //console.log("JSON.stringify(output)");
        //console.log(JSON.stringify(output));

        //console.log("scheineCount");
        //console.log(scheineCount);

        //console.log(_3101Output);
        //console.log("[3101_count]: ", _3101Count);

        //console.log(_3102Output);
        //console.log("[3102_count]: ", _3102Count);

        if(_3101Count === _3102Count) {
            scheineCount = _3101Count;
        }else{
            //console.warn(`Count mismatch: 3101=${_3101Count}, 3102=${_3102Count}`);
            scheineCount = _3102Count;
        }

        //console.log("scheineCount ", scheineCount);

        /*console.log(_0203101Output);
        console.log("[0203101_count]: ", _0203101Count);*/

        setDialogInformation({ information: "Laden...", status: "loading" });
        setOpen(true);

        await insertData(JSON.stringify(output), scheineCount);
    };

    const insertData = async (data, scheineCount) => {
        try {
            const response = await DatabaseService.insertCONData(data, scheineCount);
            if (response.success) {
                getCONData(page, rowsPerPage, search)
                    .then(() => setOpen(false))
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        setOpen(false);
                    });

            } else {
                console.error('Failed to insert data', response.message);
            }
        } catch (error) {
            console.error('Error inserting data:', error);
        }
    };

    return (
        <MuiDrawer>
            <br></br>

            <FileUploadForm onSubmit={onSubmit} />

            <br/>

            <Table_Section 
                tableTitle="CON-Daten"
                loading={loading}
                rows={rows}
                order={order}
                orderBy={orderBy}
                selected={selected}
                page={page}
                rowsPerPage={rowsPerPage}
                totalRowsCount={totalRowsCount}
                dense={dense}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSelected={setSelected}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setDense={setDense}
                onDelete={onDelete}
                setSearch={setSearch}
                search={search}
                handleCONDataView={handleCONDataView}
                handleCONDataViewWithEBM={handleCONDataViewWithEBM}
            />

            {
                dialogData.status === "loading" || dialogData.status === "view" ? (
                <DialogOutput
                    fullScreen={(dialogData.status === "view") ? true: false}
                    open={open}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    backgroundColor={dialogData.status === "view" ? '#F1F2F4' : undefined}
                    output={dialogData.status === "view" ? (
                        (!dialogData.with_ebm) ? <ViewCONData data={dialogData.information} /> : <EBM_Abrechnungskompass con_data={dialogData.information.json_data} user_input_scheine={dialogData.information.scheine_count} user_input_state={dialogData.information.kv} created_at={dialogData.information.created_at} pageUserInfo={dialogData.information.pageUserInfo} />
                      ) : null}
                />
                ) : null
            }

        </MuiDrawer>
    );
}