export default function germanFormatCurrency(number, germanCurrencySymbol = true, includeDecimals = true) {
    // Ensure the number is a valid number
    if (isNaN(number)) {
      return 'Invalid number';
    }
  
    // Format the number with decimal separator and thousands separator
    const formattedNumber = number.toLocaleString('de-DE', {
      minimumFractionDigits: includeDecimals ? 2 : 0,
      maximumFractionDigits: includeDecimals ? 2 : 0
    });
  
    // Add the currency symbol and handle negative numbers
    const formattedCurrency = `${formattedNumber}${germanCurrencySymbol ? ' €' : ''}`;
    return formattedCurrency;
}