export const insertGoaeFachgruppenvergleichCSVData = async (data, input, privatpatientinnen) => {

    try {

        const response = await fetch("/api/goae_fachgruppenvergleich_to_csv", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ csv: data, input: input, privatpatientinnen: privatpatientinnen })
        });

        return response;

    } catch (error) {
        console.log("abrechnungskompass_to_csv");
        console.error(error.message);
    }
} 