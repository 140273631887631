const unsortedSoftwareList = [
  { id: 'medical_office', label: 'Medical Office (INDAMED EDV-Entwicklung und Vertrieb GmbH)' },
  { id: 't2med', label: 'T2med (T2med GmbH & Co. KG)' },
  { id: 'medatixx', label: 'medatixx (medatixx GmbH & Co. KG)' },
  { id: 'tomedo', label: 'tomedo (Zollsoft GmbH)' },
  { id: 'epikur', label: 'Epikur (Epikur Software GmbH & Co. KG)' },
  { id: 'cliniccentre_billing_opd', label: 'ClinicCentre Billing (OPD) (Mesalvo Mannheim GmbH)' },
  { id: 'smarty', label: 'SMARTY (New Media Company GmbH & Co. KG)' },
  { id: 'duria', label: 'DURIA (Duria eG)' },
  { id: 'elefant', label: 'Elefant (HASOMED GmbH)' },
  { id: 'red_medical', label: 'RED Medical (RED Medical Systems GmbH)' },
  { id: 'insuite', label: 'inSuite (Doc Cirrus GmbH)' },
  { id: 'rescuepro', label: 'RescuePro (RescuePro Production GbR)' },
  { id: 'medavis_ris', label: 'medavis RIS (medavis GmbH)' },
  { id: 'imedone', label: 'IMedOne (Deutsche Telekom Clinical Solutions GmbH)' },
  { id: 'nexus_kis', label: 'NEXUS / KIS (NEXUS AG)' },
  { id: 'principa', label: 'principa (SIEGELE Software GmbH)' },
  { id: 'doctorly', label: 'Doctorly (Doctorly GmbH)' },
  { id: 'praxis_programm', label: 'Praxis-Programm (MediSoftware Computersysteme für Ärzte)' },
  { id: 'medvision', label: 'MEDVISION (MedVision AG)' },
  { id: 'apris', label: 'APRIS (APRIS Praxiscomputer GmbH)' },
  { id: 'medi10', label: 'Medi10 (Pharmatechnik GmbH & Co. KG)' },
  { id: 'kvdt_adt', label: 'KVDT (ADT) (IFMS - Institut für medizinische Software GmbH)' },
  { id: 'xplore_ris', label: 'Xplore RIS (EDL Software Deutschland GmbH)' },
  { id: 'eris', label: 'eRIS (Digithurst Bildverarbeitungssysteme GmbH & Co. KG)' },
  { id: 'clinisys_vianova_labor', label: 'Clinisys vianova Labor (Clinisys Deutschland GmbH)' },
  { id: 'medibit', label: 'medibit (Examion GmbH)' },
  { id: 'molis', label: 'MOLIS (CGM LAB International GmbH)' },
  { id: 'labcentre', label: 'LabCentre (Mesalvo Mannheim GmbH)' },
  { id: 'arkandus', label: 'Arkandus (arkandus GmbH)' },
  { id: 'nexus_ris', label: 'NEXUS RIS (NEXUS / CHILI GmbH)' },
  { id: 'david', label: 'David (Medat Computersysteme GmbH)' },
  { id: 'praxis4more', label: 'Praxis4More (CoKom One GmbH)' },
  { id: 'lx_labsolution', label: 'LX (labsolution S.à.r.l & Co.KG)' },
  { id: 'z1', label: 'Z1 (CompuGroup Medical Dentalsysteme GmbH)' },
  { id: 'eva', label: 'EVA (Abasoft)' },
  { id: 'opus_l', label: 'Opus::L Labormanagementsystem (Dedalus Labor GmbH)' },
  { id: 'prolab', label: 'proLAB (SysTek GmbH)' },
  { id: 'classy_rt', label: 'CLASSY RT (KHP Kelm & Homberg Produktionsgesellschaft GmbH & Co.KG)' },
  { id: 'mkis', label: 'M-KIS (Meierhofer AG)' },
  { id: 'ds_win_plus', label: 'DS-WIN-PLUS (DS-WIN-MED) (DAMPSOFT GmbH)' },
  { id: 'cgm_soul', label: 'CGM SOUL (CompuGroup Medical Deutschland AG)' },
  { id: 'iatropro', label: 'Iatropro (APM IT Bernhard Wichmann)' },
  { id: 'ekm_lab', label: 'EKM-LAB (EKM Software Entwicklung GbR)' },
  { id: 'pegasus', label: 'Pegasus Software für Pathologie und Zytologie (Pegasus Datensysteme)' },
  { id: 'glims', label: 'GLIMS (Clinisys Deutschland GmbH)' },
  { id: 'lamis', label: 'LAMIS (Fluentes Labor/Prof. Dr. Dr. Schöneshöfer)' },
  { id: 'evident', label: 'EVIDENT (EVIDENT GmbH)' },
  { id: 'eladis', label: 'ELADIS (LabKom Biochemische Dienstleistungen GmbH)' },
  { id: 'eishisto', label: 'EISHISTO (EI Systems, Andreas Eicker)' },
  { id: 'is_l_seelig', label: 'I.S.-L-SEELIG (MVZ Labor PD. Dr. Volkmann und Kollegen GBR)' },
  { id: 'pathoflow', label: 'PathoFlow (Florian Kaiser)' },
  { id: 'acetomed', label: 'ACETOmed (ACETO Softwareentwicklung GmbH)' },
  { id: 'cgm_vt', label: 'CGM VT (CGM LAB International GmbH)' },
  { id: 'map_software', label: 'MAP Software für Laborärzte (Andaco Consulting & Trading GmbH)' },
  { id: 'zymed_expert', label: 'ZyMed Expert (Dr. med. Martin Zywietz)' },
  { id: 'quickcon', label: 'QuickCON (BS software development)' },
  { id: 'med4winplus', label: 'Med4WinPlus (Müritz COMP Greifswald Computersystemhaus GmbH)' },
  { id: 'melos_labor_system', label: 'Melos-Labor-System (MELOS GmbH)'},
  { id: 'hs_laborsoftware', label: 'H&S Laborsoftware (Limbach Gruppe SE)' },
  { id: 'pallidoc', label: 'PalliDoc (Statconsult GmbH)' },
  { id: 'lis_plus', label: 'LIS++ (4labs software gmbh)' },
  { id: 'co_fox', label: 'Co-Fox Pathologie (LIMS) (Co-Fox GmbH)' },
  { id: 'medicus', label: 'MEDICUS (MEDNET Service für Ärzte AG)' },
  { id: 'medipx', label: 'MEDIPX (MEDITEC Medizinische Datentechnologie GmbH)' },
  { id: 'profimed', label: 'PROFIMED (PRO MEDISOFT AG)' },
  { id: 'lina', label: 'LINA (LUU Connect)' },
  { id: 'imp_system', label: 'IMP::System (IMP Computersysteme AG)' },
  { id: 'medi_line', label: 'MEDI_LINE (Strzata, Dr. med. Norbert)' },
  { id: 'ashvins_xis', label: 'Ashvins xIS (MedicalCommunications GmbH)' },
  { id: 'imassense', label: 'imassense ISG (imassense GmbH)' },
  { id: 'smartlis', label: 'smartLIS Laborinformationssystem Abrechnungsmodul (labdock GmbH)' },
  { id: 'rad_plus_ris', label: 'RAD+ RIS System (boos-uttenthaler-garcia RAD+ Entwicklungsgesellschaft bR)' },
  { id: 'indication', label: 'INDICATION (ET Software Developments GmbH)' },
  { id: 'c_lab', label: 'C-LAB (MVZ Labor Ludwigsburg)' },
  { id: 'qmed_praxis', label: 'QMED.PRAXIS (Schwerdtner Medizin-Software GmbH)' },
  { id: 'crosshealth', label: 'CROSSHEALTH (CLICKVALID GmbH)' },
  { id: 'vault_suite', label: 'VAULT Suite (MVZ Labor Dr. Reising-Ackermann und Kollegen GbR)' },
  { id: 'genlab8', label: 'GenLAB8 (projodis GmbH)' },
  { id: 'apraxos', label: 'apraxos (Dr. Claudia Neumann)' },
  { id: 'fidus', label: 'FIDUS (FIDUS Software Entwicklungs-GmbH)' },
  { id: 'unisolo_poesy', label: 'UNISOLO®-POESY (UNISOLO® GmbH)' },
  { id: 'sylab', label: 'SYLAB (Labor Dr. von Froreich GmbH)' },
  { id: 'dc_pathos_dc_ross', label: 'dc-pathos/dc-ross (NEXUS / DIGITAL PATHOLOGY GmbH)' },
  { id: 'promed_open', label: 'PROMED-open / X-PROMED (Clinisys Deutschland GmbH)' },
  { id: 'win_radiolog', label: 'WIN-RADIOLOG (medigration GmbH)' },
  { id: 'carw', label: 'CARW (PENTA Services GmbH & Co. KG)' },
  { id: 'imed_billing', label: 'i/med Billing (Dorner GmbH & Co. KG)' },
  { id: 'nexus_swisslab', label: 'NEXUS / SWISSLAB (NEXUS SWISSLAB GmbH)' },
  { id: 'melos', label: 'MELOS MeCom Arzt & Labor (MELOS GmbH)' },
  { id: 'disweb', label: 'DISweb (KfH - Kuratorium für Dialyse und Nierentransplantation e.V.)' },
  { id: 'pdv_fr', label: 'PDV-FR (Universitätsklinikum Freiburg AdöR)' },
  { id: 'medos', label: 'MEDOS (NEXUS / CHILI GmbH)' },
  { id: 'kiwi_kind', label: 'KiWi - KIND Praxis EDV für Windows (KIND GmbH & Co. KG)' },
  { id: 'amasys', label: 'amasys (Cerner Health Services Deutschland GmbH)' },
  { id: 'cgm_clinical', label: 'CGM CLINICAL (CGM Clinical Deutschland GmbH)' },
  { id: 'intermedinet', label: 'InterMediNet KVDT (DBI Informatik, Dirk Blume)' },
  { id: 'arztpraxis_wiegand', label: 'Arztpraxis Wiegand (APW-Wiegand - Med. Software Entwicklung und Vertrieb GmbH)' },
  { id: 'j_med', label: 'J-MED (Haase, Dipl.-Ing. Silvia GbR)' },
  { id: 'arzt_2000', label: 'ARZT 2000 (Schmidt Computersysteme)' },
  { id: 'pro_medico', label: 'Pro_Medico (Neutz GmbH Systemhaus)' },
  { id: 'ifa_augenarzt', label: 'IFA-AUGENARZT (ifa systems AG)' },
  { id: 's3_win', label: 'S3-Win (S3 Praxiscomputer GmbH / Maximilian Flender)' },
  { id: 'psychodat', label: 'PsychoDat (ergosoft GmbH)' },
  { id: 'interarzt', label: 'InterARZT (InterData Praxiscomputer GmbH)' },
  { id: 'nexus_pathologie_zytologie', label: 'NEXUS / PATHOLOGIE und NEXUS / ZYTOLOGIE (NEXUS / DIGITAL PATHOLOGY GmbH)' },
  { id: 'centricity_ris_i', label: 'Centricity RIS-i (GE Healthcare Information Technologies GmbH & Co. KG)' },
  { id: 'pegamed', label: 'PegaMed (PEGA Elektronik GmbH)' },
  { id: 'sed_doc', label: 'SED-DOC (MEDEDV Michael Henke)' },
  { id: 'sap_ambulatory_care_management', label: 'SAP Ambulatory Care Management (SAP SE)' },
  { id: 'orbis', label: 'ORBIS® (Dedalus HealthCare GmbH)' },
  { id: 'cgm_medico', label: 'CGM MEDICO (CGM Clinical Europe GmbH)' },
  { id: 'psyprax', label: 'psyprax (psyprax GmbH)' },
  { id: 'rst_med_win', label: 'RST-MED Win (Steinbrecher, Dr. Rainer - Softwareentwicklung)' },
  { id: 'med7', label: 'Med7 (Bitron GmbH)' },
  { id: 'data_al', label: 'Data-AL (Data-AL GmbH)' },
  { id: 'data_vital', label: 'DATA VITAL (CompuGroup Medical Deutschland AG)' },
  { id: 'medys', label: 'MEDYS (MEDYS GmbH)' },
  { id: 'quincy_win', label: 'QUINCY WIN (Frey ADV GmbH)' },
  { id: 'albis', label: 'ALBIS (CompuGroup Medical Deutschland AG)' },
  { id: 'cgm_m1_pro', label: 'CGM M1 PRO (CompuGroup Medical Deutschland AG)' },
  { id: 'el_elaphe_longissima', label: 'EL - Elaphe Longissima (Softland GmbH)' },
  { id: 'x_comfort', label: 'x.comfort (medatixx GmbH & Co. KG)' },
  { id: 'radcentre_billing_opd', label: 'RadCentre Billing (OPD) (Mesalvo Mannheim GmbH)' },
  { id: 'x_concept', label: 'x.concept (medatixx GmbH & Co. KG)' },
  { id: 'x_isynet', label: 'x.isynet (medatixx GmbH & Co. KG)' },
  { id: 'esqlab_online', label: 'esQlab.online (gradient. Systemintegration GmbH)' },
  { id: 'turbomed', label: 'Turbomed (CompuGroup Medical Deutschland AG)' },
  { id: 'cgm_medistar', label: 'CGM MEDISTAR (CompuGroup Medical Deutschland AG)' },
  { id: 'other', label: 'Sonstiges' }
];

export const softwareList = unsortedSoftwareList.sort((a, b) => a.label.localeCompare(b.label));
