export const downloadGoaeFachgruppenvergleichCSV = async (id) => {
    
    try {
        const response = await fetch(`/api/goae_fachgruppenvergleich_csv_download?id=${id}`);
        
        return response;

    } catch (error) {
        console.error(error.message);
    }
}